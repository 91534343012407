import { useMutation, useApolloClient } from '@apollo/client'
import Router from 'next/router'
import { setCookie } from 'nookies'
import * as R from 'ramda'
import { useContext, useState, useEffect } from 'react'

import { ClientSignUpAnalytics } from 'components/client-sign-up/analytics'
import { SESSION_TOKEN } from 'constants/cookies'
import { DAY } from 'constants/seconds'
import CmsPageContext from 'contexts/cms-page-context'
import useCurrentUser from 'hooks/use-current-user'
import urls from 'utilities/urls'
import {
  createAuthToken as createAuthTokenMutation,
  submitClientSignUpSurvey as submitClientSignUpSurveyMutation,
} from '../mutations.graphql'
import { CoachProfileSignUpAnalytics } from '../analytics'
import s from './styles.module.css'
import SignUpFormExperiment from '../../shared/sign-up-form/experiment'

const CoachProfileSignUpExperiment = ({ fields }) => {
  const apolloClient = useApolloClient()

  const {
    signUpSurveyQuestions,
    surveyId,
    query,
    getRef,
    couponCode,
    selectedPriceId,
    coach: {
      id: coachId,
      user: { firstName },
      profileImage: { url: coachProfileImageUrl },
    },
  } = useContext(CmsPageContext)
  const me = useCurrentUser().data?.me
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const [isReengagement, setIsReengagement] = useState(false)
  const [submitClientSignUpSurvey] = useMutation(
    submitClientSignUpSurveyMutation
  )
  const [createAuthToken] = useMutation(createAuthTokenMutation)
  const { header: h, subHeader: sh } = fields

  const header = h.replace(/{coachFirstName}/g, firstName)
  const subHeader = sh.replace(/{coachFirstName}/g, firstName)

  useEffect(() => {
    CoachProfileSignUpAnalytics.onViewPage({
      coachId,
    })
  }, [coachId])

  const handleSubmit = ({ utcOffset, fitnessGoals, responseParams }) => {
    const submit = async () => {
      setIsSubmitting(true)
      try {
        const {
          data: {
            submitClientSignUpSurvey: { sessionToken, paymentToken },
          },
        } = await submitClientSignUpSurvey({
          variables: {
            ref: getRef(),
            gclid: query.gclid,
            msclkid: query.msclkid,
            utcOffset,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            fitnessGoals,
            responses: responseParams,
            referredByCoachId: coachId,
            couponCode,
          },
        })

        await setCookie({}, SESSION_TOKEN, sessionToken, {
          path: '/',
          maxAge: 30 * DAY,
        })

        CoachProfileSignUpAnalytics.onSignUp({
          coachId,
        })

        Router.push(
          urls.subscribe({
            token: paymentToken,
            params: {
              isPrePay: 1,
              referredByCoachId: coachId,
              priceId: selectedPriceId || undefined,
            },
          })
        )
      } catch (serverError) {
        setIsSubmitting(false)

        if (R.test(/phone/, serverError.message)) {
          setIsReengagement(true)

          const phoneNumberResponse = R.find(R.propEq('surveyQuestionId', 12))(
            responseParams
          )
          const phoneNumber = phoneNumberResponse?.responseValue
          if (!phoneNumber) return

          // NOTE: Commenting this out for now, if we want analytics we need to rethink how to get the userId
          // or associate the user somehow in Amplitude
          // // Fetch the userId to use for identifying this user
          // // so that their events are associated with the existing client
          // const {
          //   data: { userIdToIdentifyWith },
          // } = await apolloClient.query({
          //   query: getUserIdToIdentifyWith,
          //   variables: { phoneNumber },
          //   fetchPolicy: 'network-only',
          // })

          // // Identify the user
          // AnalyticsManager.identifyUser({
          //   me: {
          //     user: {
          //       id: userIdToIdentifyWith,
          //     },
          //   },
          // })

          ClientSignUpAnalytics.onReengagement({ coachId })
        } else {
          setError(`Something went wrong. We're fixing it now`)
        }
      }
    }

    // trigger async submit
    if (!isSubmitting) submit()
  }

  const handleContinue = () => {
    const {
      user: { id: clientUserId },
    } = me
    const continueToPayment = async () => {
      const {
        data: {
          createAuthToken: { token: paymentToken },
        },
      } = await createAuthToken({
        variables: { userId: clientUserId },
      })

      CoachProfileSignUpAnalytics.onContinueSignUp({
        coachId,
      })

      Router.push(
        urls.subscribe({
          token: paymentToken,
          params: { referredByCoachId: coachId, isPrePay: 1 },
        })
      )
    }

    continueToPayment()
  }

  return (
    <section id="signUp" className={s.signUpSection}>
      <SignUpFormExperiment
        surveyId={surveyId}
        surveyQuestions={signUpSurveyQuestions}
        header={header}
        subHeader={subHeader}
        coachProfileImageUrl={coachProfileImageUrl}
        onSubmit={handleSubmit}
        onContinue={handleContinue}
        isSubmitting={isSubmitting}
        errorMessage={error}
        isReengagement={isReengagement}
        onCancelReengagement={() => setIsReengagement(false)}
      />
    </section>
  )
}

export default CoachProfileSignUpExperiment
