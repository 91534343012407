import cn from 'classnames'
import s from './styles.module.css'

export function HeroMedia({ videoFields }) {
  return (
    <div className={cn(s.container, s.fullScreen)}>
      <video
        className={s.video}
        src={`https:${videoFields.file.url}`}
        loop
        autoPlay={true}
        playsInline={true}
        muted={true}
      />
      <div className={s.overlay} />
      <div className={s.overlay2} />
    </div>
  )
}
