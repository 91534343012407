import { useMutation } from '@apollo/client'

import { SESSION_TOKEN } from 'constants/cookies'

import { DAY } from 'constants/seconds'
import CmsPageContext from 'contexts/cms-page-context'

import Router from 'next/router'
import { setCookie } from 'nookies'
import * as R from 'ramda'
import { useContext, useState } from 'react'
import urls from 'utilities/urls'
import SignUpForm from 'components/shared/sign-up-form'
import { ClientSignUpAnalytics } from '../../client-sign-up/analytics'
import { submitClientSignUpSurvey as submitClientSignUpSurveyMutation } from './mutations.graphql'
import s from './styles.module.css'
import SignUpTestimonial from './testimonial'

const SignUp = ({ fields }) => {
  const [submitClientSignUpSurvey] = useMutation(
    submitClientSignUpSurveyMutation
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const [isReengagement, setIsReengagement] = useState(false)

  const { signUpSurveyQuestions, surveyId, query, getRef, couponCode } =
    useContext(CmsPageContext)
  const { header, subHeader, primaryItems } = fields
  const {
    header: name,
    subHeader: title,
    text: testimonial,
    primaryImage,
  } = primaryItems[0].fields

  const handleSubmit = ({ utcOffset, fitnessGoals, responseParams }) => {
    const submit = async () => {
      setIsSubmitting(true)

      // prefetch survey assets for faster load
      const { href, as } = urls.clientSignUp({ questionId: 2 })
      Router.prefetch(href)

      try {
        const {
          data: {
            submitClientSignUpSurvey: { me, sessionToken },
          },
        } = await submitClientSignUpSurvey({
          variables: {
            ref: getRef(),
            gclid: query.gclid,
            msclkid: query.msclkid,
            utcOffset,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            fitnessGoals,

            responses: responseParams,
            couponCode,
          },
        })

        if (R.find(R.propEq('surveyQuestionId', 12))(responseParams)) {
          ClientSignUpAnalytics.onPhoneNumberAcquired({
            clientId: R.path(['user', 'client', 'id'], me),
          })
        }

        // LandingPageAnalytics.onSignUp({ signUpVariant })

        await setCookie({}, SESSION_TOKEN, sessionToken, {
          path: '/',
          maxAge: 30 * DAY,
        })

        Router.push(href, as)
      } catch (serverError) {
        setIsSubmitting(false)

        if (R.test(/phone/, serverError.message)) {
          setIsReengagement(true)
        } else {
          setError(`Something went wrong. We're fixing it now`)
        }
      }
    }

    // trigger async submit
    submit()
  }

  return (
    <section id="signUp" className={s.signUpSection}>
      <h2 className={s.header}>{header}</h2>
      <div className={s.main}>
        <SignUpForm
          surveyId={surveyId}
          surveyQuestions={signUpSurveyQuestions}
          subHeader={subHeader}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          errorMessage={error}
          isReengagement={isReengagement}
          onCancelReengagement={() => setIsReengagement(false)}
        />
        <SignUpTestimonial
          name={name}
          title={title}
          testimonial={testimonial}
          primaryImageCmsFields={primaryImage.fields}
        />
      </div>
    </section>
  )
}

export default SignUp
