import { createContext } from 'react'

export default createContext({
  responseBySurveyQuestionId: {},
  createResponse: () => {},
  updateResponse: () => {},
  setOnChangeResponse: () => {},
  goToNextQuestion: () => {},
  scrollToTop: () => {},
  clientId: null,
  coachId: null,
  isCoach: null,
  progress: null,
  surveyProgress: null,
  requiredSurveyProgress: null,
  client: null,
  currentCoach: null,
  clientPayload: null,
  sections: null,
  destination: null,
  headCoach: null,
  activeGroupIsComplete: null,
  isMale: false,
  activeResponseFeedback: null,
  responseSaveError: false,
})
